import BlockContent from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import Prism from 'prismjs';
import * as React from 'react';
import { useEffect } from 'react';

import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';

import Caption from '../components/Caption';
import Categories from '../components/Categories';
import Category from '../components/Category';
import Card from '../components/Card';
import PostNav from '../components/PostNav';
import Signature from '../components/Signature';
import Title from '../components/Title';

const BlogPostTemplate = ({ data }) => {
  const { post, previous, next } = data;
  const { mainImage } = post;

  const siteUrl =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? `http://localhost:8000`
      : data.site.siteMetadata.siteUrl;

  const wordCount = post.body.reduce(
    (acc, curr) => acc + (curr.children[0]?.text.split(' ').length ?? 0),
    0,
  );

  const serializers = {
    types: {
      breaks: ({ node }) => (node.hr ? <hr /> : null),
      code: ({ node: { code, language } }) => (
        <pre data-language={language}>
          <code className={`language-${language}`}>{code}</code>
        </pre>
      ),
      inlineImage: ({ node: { alt, asset, caption } }) => {
        const sanityConfig = {
          dataset: process.env.GATSBY_SANITY_DATASET,
          projectId: process.env.GATSBY_SANITY_PROJECT_ID,
        };
        const imageData = getGatsbyImageData(
          asset.id,
          { maxWidth: 1024 },
          sanityConfig,
        );
        return (
          <>
            <GatsbyImage alt={alt} image={imageData} />
            {caption && <Caption>{caption}</Caption>}
          </>
        );
      },
    },
  };

  useEffect(() => {
    Prism.highlightAll();
  });

  const structuredData = `
  {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "description": "${post.description}",
    "headline": "${post.title}",
    "dateModified": "${post.publishedAt}",
    "datePublished": "${post.publishedAt}",
    "image": "${siteUrl}/images/${post.slug.current}-share.png",
    "wordcount": "${wordCount}",
    "author": {
      "@type": "Person",
      "name": "Josh Studley",
      "url": "${siteUrl}/about"
    }
  }`;

  return (
    <Layout
      description={post.description}
      image={`/images/${post.slug.current}-share.png`}
      pagetitle={post.title}
      structuredData={structuredData}
    >
      <article>
        <PageHeader>
          <Categories>
            {post.categories?.map(({ title }) => (
              <Category key={title}>{title}</Category>
            ))}
          </Categories>

          <Title>{post.title}</Title>
        </PageHeader>
        <Card>
          {mainImage && (
            <p style={{ textAlign: 'center' }}>
              <GatsbyImage
                alt={mainImage.alt || mainImage.caption || post.title || ''}
                image={mainImage.asset.gatsbyImageData}
              />
            </p>
          )}
          {mainImage?.caption && <Caption>{mainImage.caption}</Caption>}
          <BlockContent blocks={post._rawBody} serializers={serializers} />
          <Signature />
        </Card>
      </article>

      {(next || previous) && <PostNav next={next} previous={previous} />}
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  fragment navPost on SanityPost {
    slug {
      current
    }
    title
  }
  query BlogPostByID(
    $_id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    post: sanityPost(_id: { eq: $_id }) {
      _id
      _rawBody(resolveReferences: { maxDepth: 10 })
      body {
        children {
          text
        }
      }
      categories {
        title
      }
      description
      mainImage {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
        caption
      }
      publishedAt(formatString: "MMMM DD, YYYY")
      ...navPost
    }
    previous: sanityPost(_id: { eq: $previousPostId }) {
      ...navPost
    }
    next: sanityPost(_id: { eq: $nextPostId }) {
      ...navPost
    }
  }
`;
