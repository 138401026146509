import cx from 'classnames';
import { Link } from 'gatsby';
import React, { FC } from 'react';

import * as styles from './PostNav.module.scss';

type Nav = {
  slug: {
    current: string;
  };
  title: string;
};

interface PostNavProps {
  next?: Nav;
  previous?: Nav;
}

const PostNav: FC<PostNavProps> = ({ next, previous }) => (
  <div className={cx(styles.root)}>
    {previous ? (
      <Link
        className={cx(styles.prev)}
        rel="prev"
        to={`/blog/${previous.slug.current}`}
      >
        <span aria-hidden="true">&lt;</span> {previous.title}
      </Link>
    ) : (
      <span>&nbsp;</span>
    )}
    {next && (
      <Link
        className={cx(styles.next)}
        rel="next"
        to={`/blog/${next.slug.current}`}
      >
        {next.title} <span aria-hidden="true">&gt;</span>
      </Link>
    )}
  </div>
);

export default PostNav;
