import cx from 'classnames';
import React, { FC } from 'react';

import * as styles from './Signature.module.scss';

const Title: FC = () => (
  <div aria-hidden="true" className={cx(styles.root)}>{`{ ȷs }`}</div>
);

export default Title;
